import type { PropsWithChildren } from "react";
import { useUserInfo } from "../../hooks/draw-handlings.hook";

export function Layout(props: PropsWithChildren) {
  const { room } = useUserInfo();

  return (
    <main className="p-2">
      {room && <p className="text-xl">Room id: {room}</p>}
      {props.children}
    </main>
  );
}
