import { useState } from "react";
import { useUserInfo } from "../hooks/draw-handlings.hook";
import { Button, Input } from "./presentation";
import { ReactSketchCanvas } from "react-sketch-canvas";

function generateRandomRoomId() {
  return Math.random().toString(36).substring(2, 7);
}

export function ChooseRoom() {
  const [roomid, setRoomId] = useState("");
  const { setRoom } = useUserInfo();

  return (
    <div className="flex flex-col gap-4">
      <p className="text-2xl">Choose a room Room</p>
      <Input
        type="text"
        placeholder="roomid"
        value={roomid}
        onChange={(e) => setRoomId(e.target.value.toLowerCase())}
      />
      <Button onClick={() => setRoom(roomid)}>Join Room</Button>
      <p className="text-2xl">Or create a new room</p>
      <Button
        className="bg-yellow-500 hover:bg-yellow-700"
        onClick={() => setRoom(generateRandomRoomId())}
      >
        Create new Room
      </Button>
    </div>
  );
}
