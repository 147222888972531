import { useUserInfo } from "../hooks/draw-handlings.hook";
import { Button } from "./presentation";

export function Lobby(props: {
  players: { uuid: string; username: string }[];
  startGame: () => void;
  roomId: string;
}) {
  const { uuid, color } = useUserInfo();

  return (
    <div className="flex flex-col gap-4">
      <p className="text-2xl">This is the lobby!</p>
      <div>
        {props.players.map((player, index) => {
          const you = player.uuid === uuid;
          return (
            <div className="flex gap-1" key={player.uuid}>
              <p className="text-lg" key={index}>
                {player.username}
              </p>
              {you && (
                <p className="text-sm mt-auto mb-auto" style={{ color }}>
                  (you)
                </p>
              )}
            </div>
          );
        })}
      </div>
      <Button onClick={props.startGame}>Start the Game!!</Button>
    </div>
  );
}
