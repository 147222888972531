import usePartySocket from "partysocket/react";
import { createContext, useContext, useEffect, useState } from "react";
import {
  type UpadeGameStateEvent,
  type GameState,
  type StartGameEvent,
  type ChooseWordEvent,
  type DrawLineEvent,
  type VoteEvent,
  type GuessCompleteEvent,
} from "../../party/game-state";
import type { CanvasPath } from "react-sketch-canvas";

export function useGameActions(roomId: string, userUuid: string) {
  const [gameState, setGameState] = useState<GameState | undefined>();
  const socket = usePartySocket({
    // host defaults to the current URL if not set
    //host: process.env.PARTYKIT_HOST,
    // we could use any room name here
    room: roomId,
    id: userUuid,
    onMessage(evt) {
      const data = JSON.parse(evt.data) as UpadeGameStateEvent;
      console.log("onMessage", data);
      if (data.type === "game/update") {
        setGameState(data.meta);
      }
    },
  });

  return {
    gameState,
    startGame() {
      const payload: StartGameEvent = {
        type: "game/start",
      };
      socket.send(JSON.stringify(payload));
    },
    chooseWord(word: string) {
      const payload: ChooseWordEvent = {
        type: "word/choose",
        meta: word,
      };
      socket.send(JSON.stringify(payload));
    },
    drawLine(line: CanvasPath) {
      const payload: DrawLineEvent = {
        type: "line/draw",
        meta: { ...line, uuid: userUuid },
      };
      socket.send(JSON.stringify(payload));
    },
    voteComplete() {
      const payload: VoteEvent = {
        type: "guess/vote",
      };
      socket.send(JSON.stringify(payload));
    },
    guessComplete() {
      const payload: GuessCompleteEvent = {
        type: "guess/complete",
      };
      socket.send(JSON.stringify(payload));
    },
  };
}

const UserInfoContext = createContext<
  | undefined
  | {
      uuid: string;
      color: string;
      room: string | null;
      setRoom: (room: string) => void;
    }
>(undefined);

const aviableColors = [
  "red",
  "green",
  "blue",
  "yellow",
  "purple",
  "orange",
  "pink",
  "brown",
];

// generate a random UUID without the crypto API
function randomUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function UserInfoProvider({ children }: { children: React.ReactNode }) {
  const [uuid] = useState(localStorage.getItem("useruuid") || randomUUID());

  const [color] = useState(
    localStorage.getItem("usercolor") ||
      aviableColors[Math.floor(Math.random() * aviableColors.length)],
  );

  const [room, setRoom] = useState<string | null>(null);

  useEffect(() => {
    localStorage.setItem("useruuid", uuid);
    localStorage.setItem("usercolor", color);
  }, [uuid, color]);

  return (
    <UserInfoContext.Provider
      value={{
        uuid,
        color,
        room,
        setRoom,
      }}
    >
      {children}
    </UserInfoContext.Provider>
  );
}

export function useUserInfo() {
  const context = useContext(UserInfoContext);
  if (context === undefined) {
    throw new Error("useUserInfo must be used within a UserInfoProvider");
  }
  return context;
}
