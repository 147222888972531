import { useState } from "react";
import { Button, Input } from "./presentation";

export function ChooseWord(props: { chooseWord: (word: string) => void }) {
  const [word, setWord] = useState("");

  return (
    <div className="flex gap-4 flex-col">
      <p className="">It's your turn, choose the word</p>
      <Input
        placeholder="Type the word"
        value={word}
        onChange={(e) => setWord(e.target.value.toLowerCase())}
      />
      <Button disabled={word.length < 3} onClick={() => props.chooseWord(word)}>
        Let's go!
      </Button>
    </div>
  );
}
