import { createRoot } from "react-dom/client";
import { UserInfoProvider } from "./hooks/draw-handlings.hook";
import { AppRouter } from "./components/router";
import { Layout } from "./components/presentation";

function App() {
  return (
    <UserInfoProvider>
      <Layout>
        <AppRouter />
      </Layout>
    </UserInfoProvider>
  );
}

createRoot(document.getElementById("app")!).render(<App />);
