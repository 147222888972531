import { useEffect, useRef } from "react";
import {
  ReactSketchCanvas,
  type ReactSketchCanvasRef,
  type CanvasPath,
} from "react-sketch-canvas";
import { useUserInfo } from "../hooks/draw-handlings.hook";
import type { GameState } from "../../party/game-state";

export function DrawingBoard(props: {
  drawLine: (path: CanvasPath) => void;
  gameState: GameState;
  showWord: boolean;
}) {
  const { uuid, color } = useUserInfo();
  const canvasRef = useRef<ReactSketchCanvasRef>(null);
  const isYourTurn = props.gameState?.drawPlayerTurn === uuid;
  const isMaster = props.gameState?.currentMaster === uuid;

  useEffect(() => {
    if (canvasRef.current && props.gameState?.drawingBoard) {
      canvasRef.current.clearCanvas();
      canvasRef.current.loadPaths(props.gameState?.drawingBoard);
    }
  }, [String(props.gameState?.drawingBoard)]);

  return (
    <div>
      {props.showWord && (
        <p className="text-sm">The word is: {props.gameState?.currentWord}</p>
      )}
      {isMaster ? (
        <p className="text-lg">Sit back and enjoi the mess 🤩</p>
      ) : isYourTurn ? (
        <p className="text-lg">Draw here</p>
      ) : (
        <p className="text-lg">Another player is drawing</p>
      )}
      <ReactSketchCanvas
        ref={canvasRef}
        width="100%"
        height="100vh"
        canvasColor="transparent"
        strokeColor={color}
        strokeWidth={4}
        readOnly={!isYourTurn}
        onStroke={(data) => {
          if (data.paths.length > 1) {
            props.drawLine(data);
          }
        }}
      />
    </div>
  );
}
