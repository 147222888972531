import { useGameActions, useUserInfo } from "../hooks/draw-handlings.hook";
import { ChooseRoom } from "./choose-room";
import { ChooseWord } from "./choose-word";
import { DrawingBoard } from "./drawing-board";
import { Lobby } from "./lobby";
import { Button } from "./presentation";

export function AppRouter() {
  const { room } = useUserInfo();

  if (!room) {
    return <ChooseRoom />;
  }

  return <GameRoutes />;
}

export function GameRoutes() {
  const { room, uuid } = useUserInfo();
  const {
    gameState,
    startGame,
    guessComplete,
    voteComplete,
    chooseWord,
    drawLine,
  } = useGameActions(room!, uuid);

  if (gameState?.status === "drawing") {
    const youfakeArtist = uuid === gameState.currentFakeArtist;
    return (
      <DrawingBoard
        showWord={!youfakeArtist}
        drawLine={drawLine}
        gameState={gameState}
      />
    );
  }

  if (gameState?.status === "choosing") {
    if (gameState?.currentMaster === uuid) {
      return <ChooseWord chooseWord={chooseWord} />;
    }

    const youfakeArtist = uuid === gameState.currentFakeArtist;
    return (
      <div>
        <p className="text-sm">
          Waiting for the turn player to choose a word 🧐...
        </p>
        {youfakeArtist && (
          <p className="text-sm">PS. You are the fake artist!</p>
        )}
      </div>
    );
  }

  if (gameState?.status === "lobby") {
    return (
      <Lobby
        players={gameState?.players || []}
        startGame={startGame}
        roomId={room!}
      />
    );
  }

  if (gameState?.status === "voting") {
    if (gameState?.currentMaster === uuid) {
      return (
        <div className="flex flex-col gap-4">
          <p className="text-2xl">Vote for the fake artist</p>
          <p className="text-lg">Count down to three to sincronize the vote!</p>
          <Button onClick={() => voteComplete()}>Vote completed!</Button>
        </div>
      );
    }
    return (
      <div className="flex flex-col gap-4">
        <p className="text-2xl">Vote for the fake artist</p>
        <p className="text-lg">
          Now the turn palyer will be counting down then everyone will vote for
          who they think the fake artist is!
        </p>
      </div>
    );
  }

  if (gameState?.status === "guessing") {
    const fakeArtist = gameState.players.find(
      (player) => player.uuid === gameState.currentFakeArtist,
    );

    const youfakeArtist = uuid === gameState.currentFakeArtist;

    if (youfakeArtist) {
      return (
        <div className="flex flex-col gap-4">
          <p className="text-2xl">You are the fake artist!</p>
          <p className="text-lg">
            If you have been voted try to guess the word otherwise you have won!
          </p>
          <Button onClick={() => guessComplete()}>Go on</Button>
        </div>
      );
    }

    return (
      <div className="flex flex-col gap-4">
        <p className="text-2xl">The fake artist is {fakeArtist?.username}!</p>
      </div>
    );
  }

  if (gameState?.status === "reveal") {
    const fakeArtist = gameState.players.find(
      (player) => player.uuid === gameState.currentFakeArtist,
    );

    return (
      <div className="flex flex-col gap-4">
        <p className="text-2xl">
          The word was {gameState.currentWord} did {fakeArtist?.username} guess
          it?
        </p>
        <p>Now calculate the points accordingly and do another round!</p>
        <Button onClick={() => startGame()}>Start next round</Button>
      </div>
    );
  }

  return <div>Something is not right refresh the page...</div>;
}
